body {
	background-color: #ffea7c;
	color: #d7300e;
}

.app-logo {
	pointer-events: none;
}

.location-marker {
	height: 48px;
	width: 48px;
	background-image: url('./icons/location-icon.png');
	background-size: contain;
}

.restaurant-marker {
	height: 40px;
	width: 40px;
	background-image: url('./icons/restaurant-icon.png');
	background-size: contain;
}
